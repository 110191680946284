import axios from 'axios';
import config from '../config';
import * as types from './types';

axios.defaults.withCredentials = true;
const api = config.api.URL;

export const updateEditorState = _editorState => async dispatch => {
  // id: 30,
  // itemId: item-30
  // _editorState: editorstate

  try {
    dispatch({
      type: types.UPDATE_EDITOR_STATE,
      payload: _editorState,
    });
  } catch (e) {
    console.log('update editor state error: ', e);
  }
};

export const getItemDetails = (item, callBack) => async dispatch => {
  try {
    if (!item.hasOwnProperty('settings')) {
      dispatch({
        type: types.ITEM_LOADING_UI,
        payload: item.id,
      });

      const response = await axios.get(`${api}/items/${item.id}`);
      // console.log('item details response: ', response);
      dispatch({
        type: types.LOAD_ITEM_DETAILS,
        payload: response.data,
      });

      callBack();
    } else {
      // already have data locally
      callBack();
    }
  } catch (e) {
    console.log('load item details error: ', e);
  }
};

export const updateItemObject = itemAttribute => dispatch => {
  try {
    dispatch({
      type: types.UPDATE_ITEM_OBJECT,
      payload: itemAttribute,
    });
  } catch (e) {
    console.log('update Item Object error: ', e);
  }
};

// Update the contract on state but don't activate the autoSave middleware
export const updateExecuteContractItemObject = itemAttribute => dispatch => {
  try {
    dispatch({
      type: types.UPDATE_EXECUTE_CONTRACT_ITEM_OBJECT,
      payload: itemAttribute,
    });
  } catch (e) {
    console.log('update Item Object error: ', e);
  }
};

export const updateItemAttribute = itemAttribute => dispatch => {
  try {
    dispatch({
      type: types.UPDATE_ITEM_ATTRIBUTE,
      payload: itemAttribute,
    });
  } catch (e) {
    console.log('update item attribute error: ', e);
  }
};

export const updatePackage = (
  packageId,
  updateObject,
  callBack
) => async dispatch => {
  try {
    const response = await axios.put(
      `${api}/packages/${packageId}/attributes`,
      updateObject
    );

    if (updateObject.required === false) {
      dispatch({
        type: types.UPDATE_PACKAGE_ITEMS_REQUIRED,
        payload: response.data,
      });
    }

    dispatch({
      type: types.UPDATE_PACKAGE,
      payload: response.data,
    });
    callBack();
  } catch (e) {
    console.log('update package error: ', e);
  }
};

export const updateSectionObject = sectionAttribute => dispatch => {
  try {
    dispatch({
      type: types.UPDATE_SECTION_OBJECT,
      payload: sectionAttribute,
    });
  } catch (e) {
    console.log('update Section Object error: ', e);
  }
};

export const updateSectionPermission = (
  templateId,
  userGroupIds,
  callBack
) => async dispatch => {
  try {
    const response = await axios.put(
      `${api}/sections/${templateId}/usergroups`,
      {
        userGroupsCanEdit: userGroupIds,
      }
    );

    dispatch({
      type: types.UPDATE_SECTION_PERMISSION,
      payload: response.data,
    });
    callBack();
  } catch (e) {
    console.log('update Section Permission error: ', e);
  }
};

export const updateContractUsergroup = (
  templateId,
  userGroupIds,
  callBack
) => async dispatch => {
  try {
    const response = await axios.put(`${api}/contracts/${templateId}/update`, {
      userGroups: userGroupIds,
    });

    dispatch({
      type: types.UPDATE_CONTRACT_PERMISSION,
      payload: response.data,
    });
    callBack();
  } catch (e) {
    console.log('update contract Permission error: ', e);
  }
};

export const updateContractName = (
  templateId,
  name,
  callBack = () => { }
) => async dispatch => {
  try {
    dispatch({ type: types.CONTRACT_AUTOSAVE_STARTED });

    const response = await axios.put(`${api}/contracts/${templateId}/update`, {
      name,
    });
    dispatch({
      type: types.TEMPLATE_NAME_CHANGED,
      payload: response.data,
    });

    dispatch({ type: types.TEMPLATE_AUTOSAVE_SUCCEEDED });

    callBack();
  } catch (e) {
    console.log('update contract name error: ', e);
    let msg = e.message;
    if (e.response && e.response.data && e.response.data.error) {
      msg = e.response.data.error.message;
    }
    dispatch({ type: types.CONTRACT_AUTOSAVE_FAILED, payload: msg });
  }
};

export const updateContractLogo = (
  templateId,
  logo,
  callBack = () => { }
) => async dispatch => {
  try {
    dispatch({ type: types.CONTRACT_AUTOSAVE_STARTED });

    let customLogo = {
      path: 'https://d3gqnlyjfmjmla.cloudfront.net/icons/logo-marketing360-dark-large.png'
    }

    switch (logo) {
      case 'm360tm':
        customLogo.path = 'https://d3gqnlyjfmjmla.cloudfront.net/icons/logo-marketing360-large-tm.png';
        break;
      /*case 'torstar':
        customLogo.path = 'https://d3gqnlyjfmjmla.cloudfront.net/icons/torstar-logo.png';
        break;*/
      case 'aba':
        customLogo.path = 'https://d3gqnlyjfmjmla.cloudfront.net/icons/aba-logo.png';
        break;
      case 'ag':
        customLogo.path = 'https://d3gqnlyjfmjmla.cloudfront.net/icons/ag360logo.png';
        break;
      default:
        break;
    }

    const response = await axios.put(`${api}/contracts/${templateId}/update`, {
      customLogo,
    });
    dispatch({
      type: types.TEMPLATE_LOGO_CHANGED,
      payload: response.data,
    });

    dispatch({ type: types.TEMPLATE_AUTOSAVE_SUCCEEDED });

    callBack();
  } catch (e) {
    console.log('update template logo error: ', e);
    let msg = e.message;
    if (e.response && e.response.data && e.response.data.error) {
      msg = e.response.data.error.message;
    }
    dispatch({ type: types.CONTRACT_AUTOSAVE_FAILED, payload: msg });
  }
};

export const updateBillingPaymentState = (
  templateId,
  event,
  callBack = () => { }
) => async dispatch => {
  try {
    dispatch({ type: types.CONTRACT_AUTOSAVE_STARTED });

    await axios.put(`${api}/contracts/${templateId}/update`, {
      billingPaymentEnabled: event.target.checked,
    });

    dispatch({ type: types.TEMPLATE_AUTOSAVE_SUCCEEDED });

    callBack();
  } catch (e) {
    console.log('update billing payment state error: ', e);
    let msg = e.message;
    if (e.response && e.response.data && e.response.data.error) {
      msg = e.response.data.error.message;
    }
    dispatch({ type: types.CONTRACT_AUTOSAVE_FAILED, payload: msg });
  }
};

export const createContract = (contractConfig, callBack) => async dispatch => {
  try {
    const postBody = {
      createContractFromTemplate: contractConfig.templateId,
      businessName: contractConfig.businessName,
      contractClient: {
        name: contractConfig.clientName,
        email: contractConfig.clientEmail,
      },
    };

    const response = await axios.post(`${api}/contracts`, postBody);

    // console.log('create contract response: ', response);

    localStorage.setItem('c-id', response.data.id);

    dispatch({
      type: types.CREATE_CONTRACT,
      payload: response.data,
    });

    callBack(response.data);
  } catch (e) {
    console.log('create contracts error: ', e);
    dispatch({
      type: types.CREATE_CONTRACT_ERROR,
      payload: 'Create Contract Error',
    });
    dispatch({ type: types.HIDE_GLOBAL_LOADER, payload: {} });
  }
};

export const downloadContracts = (postBody, callBack) => async dispatch => {
  try {
    //console.log("postBody >",postBody);
    let csvDataArray = [];

    const response = await axios.post(`${api}/contracts/download-csv`, postBody);
    csvDataArray = response.data;
    callBack(csvDataArray);
    // dispatch({
    //   type: types.DOWNLOAD_CONTRACT,
    //   payload: response.data,
    // });

    //callBack(response.data);
    //callBack(null);
  } catch (e) {
    // console.log('download contracts error: ', e);
    // dispatch({
    //   type: types.DOWNLOAD_CONTRACT_ERROR,
    //   payload: 'Download Contracts Error',
    // });
    dispatch({ type: types.HIDE_GLOBAL_LOADER, payload: {} });
  }
};

export const softDeleteTemplate = (templateId, cb) => async dispatch => {
  const response = await axios.delete(`${api}/templates/${templateId}/soft`);
  cb(response.data);
  dispatch({
    type: types.SOFT_DELETE_TEMPLATE,
    payload: response.data,
  });
};

export const copyTemplate = (templateId, cb) => async dispatch => {
  try {
    dispatch({
      type: types.SHOW_GLOBAL_LOADER,
      payload: `Copying template. Please wait...`,
    });

    const res = await axios.post(`${api}/templates/copy/${templateId}`);

    dispatch({
      type: types.COPY_TEMPLATE_SUCCEEDED,
      payload: res.data,
    });

    dispatch({
      type: types.HIDE_GLOBAL_LOADER,
      payload: {},
    });

    if (cb) cb();
  } catch (e) {
    console.log('Error copying template: ', e);

    dispatch({
      type: types.COPY_TEMPLATE_FAILED,
      payload: 'Error copying template. Contact support.',
    });
    dispatch({
      type: types.HIDE_GLOBAL_LOADER,
      payload: {},
    });
  }
};

export const deleteSection = (sectionId, cb) => async dispatch => {
  const response = await axios.delete(`${api}/sections/${sectionId}`);
  cb(response.data);
  dispatch({
    type: types.DELETE_SECTION,
    payload: response.data,
  });
};

export const deletePackage = (packageId, cb) => async dispatch => {
  const response = await axios.delete(`${api}/packages/${packageId}`);
  cb(response.data);
  dispatch({
    type: types.DELETE_PACKAGE,
    payload: response.data,
  });
};

export const deleteItem = (itemId, cb) => async dispatch => {
  try {
    const response = await axios.delete(`${api}/items/${itemId}`);
    cb(response.data);
    dispatch({
      type: types.DELETE_CONTRACT_ITEM,
      payload: {
        itemId,
      },
    });
  } catch (e) {
    console.log('delete item error: ', e);
  }
};

export const createTemplate = (
  templateConfig,
  callBack = () => { }
) => async dispatch => {
  try {
    const postBody = {
      templateName: templateConfig.templateName,
    };

    const response = await axios.post(`${api}/templates`, postBody);

    console.log('response: ', response);

    localStorage.setItem('t-id', response.data.id);

    dispatch({
      type: types.CREATE_TEMPLATE,
      payload: response.data,
    });

    callBack(response.data.id);
  } catch (e) {
    let msg = e.message;
    if (e.response && e.response.data && e.response.data.error) {
      msg = e.response.data.error.message;
    }

    dispatch({
      type: types.CREATE_TEMPLATE_ERROR,
      payload: msg,
    });
    dispatch({ type: types.HIDE_GLOBAL_LOADER, payload: {} });
    callBack(null, msg);
  }
};

export const reorderSections = listParams => dispatch => {
  try {
    dispatch({
      type: types.REORDER_SECTIONS,
      payload: listParams,
    });
  } catch (e) {
    console.log('reorder sections error: ', e);
  }
};

export const reorderPackages = listParams => dispatch => {
  try {
    dispatch({
      type: types.REORDER_PACKAGES,
      payload: listParams,
    });
  } catch (e) {
    console.log('reorder package error: ', e);
  }
};

export const reorderItems = listParams => dispatch => {
  try {
    dispatch({
      type: types.REORDER_ITEMS,
      payload: listParams,
    });
  } catch (e) {
    console.log('reorder item error: ', e);
  }
};

export const toggleItemActive = itemId => dispatch => {
  try {
    dispatch({
      type: types.TOGGLE_ITEM_ACTIVE,
      payload: itemId,
    });
  } catch (e) {
    console.log('toggle item error: ', e);
  }
};

export const loadContractToBuilder = (
  contractId,
  callBack = () => { }
) => async dispatch => {
  try {
    const response = await axios.get(`${api}/contracts/${contractId}`);

    localStorage.setItem('c-id', contractId);

    dispatch({
      type: types.LOAD_CONTRACT_TO_BUILDER,
      payload: response.data,
    });

    dispatch({
      type: types.CONTRACT_LOADING_DONE,
    });
    callBack();
  } catch (e) {
    if (e.request && e.request.status === 404) {
      dispatch({
        type: types.CONTRACT_LOADING_NOT_FOUND,
        payload: 'Unable to load contract.',
      });
    } else {
      dispatch({
        type: types.CONTRACT_LOADING_FAILED,
        payload: 'Unable to load contract.',
      });
    }
  }
};

export const loadTemplateToBuilder = templateId => async dispatch => {
  try {
    const response = await axios.get(`${api}/templates/${templateId}`);

    // console.log('template response: ', response);

    dispatch({
      type: types.LOAD_TEMPLATE_TO_BUILDER,
      payload: response.data,
    });
    dispatch({
      type: types.CONTRACT_LOADING_DONE,
    });
  } catch (e) {
    console.log('load template to builder error: ', e);
    if (e.status === 404) {
      dispatch({
        type: types.CONTRACT_LOADING_NOT_FOUND,
        payload: 'Unable to load template.',
      });
    } else {
      dispatch({
        type: types.CONTRACT_LOADING_FAILED,
        payload: 'Unable to load template.',
      });
    }
  }
};

export const fetchTemplates = (
  query = '',
  callBack = () => { },
  page = 1,
  limit = 10
) => async dispatch => {
  try {
    const response = await axios.get(
      `${api}/templates/?page=${page}&limit=${limit}${query}`
    );
    dispatch({
      type: types.FETCH_TEMPLATES,
      payload: { ...response.data, filter: query },
    });

    if (callBack) {
      callBack();
    }
  } catch (e) {
    // console.log('fetch templates error: ', e);
    dispatch({
      type: types.FETCH_TEMPLATES_ERROR,
      payload: 'Fetch Templates Error',
    });
    dispatch({ type: types.HIDE_GLOBAL_LOADER, payload: {} });
  }
};

export const fetchTemplatesModal = (callback = () => { }) => async dispatch => {
  try {
    const response = await axios.get(`${api}/templates/modal`);
    dispatch({
      type: types.FETCH_TEMPLATES,
      payload: response.data,
    });

    if (callback) {
      callback();
    }
  } catch (e) {
    // console.log('fetch templates error: ', e);
    dispatch({
      type: types.FETCH_TEMPLATES_ERROR,
      payload: 'Fetch Templates Error',
    });
    dispatch({ type: types.HIDE_GLOBAL_LOADER, payload: {} });
  }
};

export const fetchContractLog = query => async dispatch => {
  try {
    // console.log(query);
    const response = await axios.get(`${api}/contracts/logs/id/${query}`);
    // console.log(response);
    dispatch({
      type: types.FETCH_CONTRACT_LOG,
      payload: response,
    });
  } catch (e) {
    dispatch({
      type: types.FETCH_CONTRACT_LOG_ERROR,
      payload: 'Fetch Contract Log Error',
    });
    dispatch({ type: types.HIDE_GLOBAL_LOADER, payload: {} });
  }
};

export const fetchContracts = (
  query = '',
  callBack = () => { },
  page = 1,
  limit = 10
) => async dispatch => {
  try {
    const response = await axios.get(
      `${api}/contracts/?page=${page}&limit=${limit}${query}`
    );
    dispatch({
      type: types.FETCH_CONTRACTS,
      payload: { ...response.data, filter: query },
    });
    callBack();
  } catch (e) {
    console.log('fetch contracts error: ', e);
    dispatch({
      type: types.FETCH_CONTRACTS_ERROR,
      payload: 'Fetch Contracts Error',
    });
    dispatch({ type: types.HIDE_GLOBAL_LOADER, payload: {} });
  }
};

export const searchUsers = (query = '', callBack) => async dispatch => {
  try {
    const response = await axios.get(`${api}/users/search?userQuery=${query}`);
    if (response.status === 200) {
      dispatch({
        type: types.SEARCH_USERS,
        payload: response.data,
      });
      callBack();
    }
  } catch (e) {
    console.log('fetch contracts error: ', e);
    dispatch({
      type: types.SEARCH_USERS_ERROR,
      payload: 'Search Users Error',
    });
  }
};

export const selectUser = (value, callBack) => async dispatch => {
  try {
    dispatch({
      type: types.SELECT_USER,
      payload: value,
    });
    callBack();
  } catch (e) {
    console.log('select user error: ', e);
    dispatch({
      type: types.SELECT_USER_ERROR,
      payload: 'Select User Error',
    });
  }
};

export const searchConsultants = (
  query = '',
  callBack = () => { }
) => async dispatch => {
  try {
    const response = await axios.get(
      `${api}/consultants/search?userQuery=${query}`
    );

    if (response.status === 200) {
      dispatch({
        type: types.SEARCH_CONSULTANTS,
        payload: response.data,
      });
      callBack();
    }
  } catch (e) {
    console.log('fetch contracts error: ', e);
    dispatch({
      type: types.SEARCH_USERS_ERROR,
      payload: 'Search Users Error',
    });
  }
};

export const loadContractToState = query => async dispatch => {
  try {
    const response = await axios.get(`${api}/contracts/export/${query}`);

    dispatch({
      type: types.LOAD_CONTRACT_TO_STATE,
      payload: response.data,
    });

    dispatch({
      type: types.CONTRACT_LOADING_DONE,
    });
  } catch (e) {
    console.log('error in load contract: ', e);
    if (e.request && e.request.status === 404) {
      dispatch({
        type: types.CONTRACT_LOADING_NOT_FOUND,
        payload: e,
      });
    } else {
      dispatch({
        type: types.CONTRACT_LOADING_FAILED,
        payload: e,
      });
    }
  }
};

export const getContactTotals = id => async dispatch => {
  try {
    const response = await axios.get(`${api}/contracts/${id}/totals`);

    dispatch({
      type: types.GET_CONTRACT_TOTALS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: types.API_ERROR,
      payload: 'Could update contract totals.',
    });
  }
};

export const executeContract = (
  token,
  contract,
  signatureItemId,
  signatureImage,
  callBack
) => async dispatch => {
  try {
    const response = await axios.put(`${api}/contracts/execute/${token}`, {
      contract,
      signatureItemId,
      signatureImage,
    });

    dispatch({
      type: types.EXECUTE_CONTRACT,
      payload: response.data,
    });

    if (callBack) {
      callBack();
    }
  } catch (e) {
    console.log('error executing contract: ', e);
    dispatch({
      type: types.HIDE_GLOBAL_LOADER,
      payload: {},
    });
    dispatch({
      type: types.EXECUTE_CONTRACT_FAILED,
      payload: 'Error executing contract. Contact support.',
    });
  }
};

export const rejectContract = (contractId, callBack) => async dispatch => {
  try {
    dispatch({
      type: types.SHOW_GLOBAL_LOADER,
      payload: `Submitting contract rejection. Please wait...`,
    });

    const response = await axios.post(`${api}/contracts/reject/${contractId}`);

    dispatch({
      type: types.REJECT_CONTRACT,
      payload: response.data,
    });
    dispatch({
      type: types.HIDE_GLOBAL_LOADER,
      payload: {},
    });

    if (callBack) callBack();
  } catch (e) {
    console.log('error rejecting contract: ', e);
    dispatch({
      type: types.HIDE_GLOBAL_LOADER,
      payload: {},
    });
    dispatch({
      type: types.REJECT_CONTRACT_FAILED,
      payload: 'Error rejecting contract. Contact support.',
    });
  }
};

export const generatePDF = (contractId, token) => async dispatch => {
  try {
    dispatch({
      type: types.EXECUTED_PDF_GENERATING,
      payload: 'Generating PDF',
    });

    const res = await axios.post(
      `${api}/contracts/${contractId}/export/pdf/${token}`
    );

    // If error on the generating the PDF.
    if (res.status !== 200) {
      throw new Error(res.data);
    }

    dispatch({
      type: types.EXECUTED_PDF_GENERATING_DONE,
      payload: res.data,
    });
  } catch (e) {
    console.log('Error generating the PDF: ', e);
    dispatch({
      type: types.EXECUTED_PDF_GENERATING_ERROR,
      payload: 'Error while generating PDF',
    });
  }
};

export const notifyParties = contractId => async dispatch => {
  try {
    const res = await axios.post(`${api}/contracts/${contractId}/email/`);

    if (res.status !== 200) {
      console.log('notify res: ', res);
      throw new Error(res.data);
    }

    dispatch({
      type: types.NOTIFY_PARTIES_DONE,
    });
  } catch (e) {
    console.log('Error notifying the parties: ', e);
    dispatch({
      type: types.NOTIFY_PARTIES_ERROR,
      payload: 'Error while notifying parties',
    });
  }
};

export const copyContract = (contractId, callBack) => async dispatch => {
  try {
    dispatch({
      type: types.SHOW_GLOBAL_LOADER,
      payload: `Copying contract. Please wait...`,
    });

    const res = await axios.post(`${api}/contracts/copy/${contractId}`);

    dispatch({
      type: types.COPY_CONTRACT_SUCCEEDED,
      payload: res.data,
    });

    dispatch({
      type: types.HIDE_GLOBAL_LOADER,
      payload: {},
    });

    if (callBack) callBack();
  } catch (e) {
    console.log('Error copying contract: ', e);

    dispatch({
      type: types.COPY_CONTRACT_FAILED,
      payload: 'Error copying contract. Contact support.',
    });
    dispatch({
      type: types.HIDE_GLOBAL_LOADER,
      payload: {},
    });
  }
};

export const deleteContract = (contractId, callBack) => async dispatch => {
  try {
    dispatch({
      type: types.SHOW_GLOBAL_LOADER,
      payload: '',
    });

    const res = await axios.delete(`${api}/contracts/${contractId}`);

    dispatch({
      type: types.DELETE_CONTRACT,
      payload: res.data.contract,
    });

    if (callBack) callBack();
  } catch (e) {
    dispatch({
      type: types.DELETE_CONTRACT_ERROR,
      payload: e.response.data.error
        ? e.response.data.error.message
        : e.message,
    });
  } finally {
    dispatch({
      type: types.HIDE_GLOBAL_LOADER,
    });
  }
};

export const finalizeContract = (contractId, leadId, callBack) => async dispatch => {
  try {
    const response = await axios.post(
      `${api}/contracts/finalize/${contractId}`,
      {
        leadId,
      }
    );

    if (response.status !== 200) {
      throw new Error('Error when finalizing contract.....');
    }

    dispatch({
      type: types.FINALIZE_CONTRACT,
      payload: response.data,
    });
    if (callBack) callBack(true);
  } catch (e) {
    console.log(`Error finalizing the contract, ${e}`);
    dispatch({
      type: types.FINALIZE_CONTRACT_FAILED,
    });
    if (callBack) callBack(false);
  }
};

export const addSignatureToState = (item, img) => dispatch => {
  dispatch({
    type: types.ADD_SIGNATURE_TO_STATE,
    payload: { item, img },
  });
};

export const contractSigned = token => async dispatch => {
  try {
    dispatch({
      type: types.CONTRACT_SIGNED,
    });
  } catch (e) {
    console.log('error marking contract as signed: ', e);
    // todo dispatch executing error
  }
};

export const contractNotSigned = token => async dispatch => {
  try {
    dispatch({
      type: types.CONTRACT_NOT_SIGNED,
    });
  } catch (e) {
    console.log('error marking contract as not signed: ', e);
    // todo dispatch executing error
  }
};

export const startAutoSaveLoder = callBack => dispatch => {
  dispatch({ type: types.CONTRACT_AUTOSAVE_STARTED });
  callBack();
};

export const autoSaveSuceeded = () => dispatch => {
  dispatch({ type: types.CONTRACT_AUTOSAVE_SUCCEEDED });
};

export const autoTemplateSaveSuceeded = () => dispatch => {
  dispatch({ type: types.TEMPLATE_AUTOSAVE_SUCCEEDED });
};

export const addSignerSectionToTemplate = (
  templateId,
  callBack
) => async dispatch => {
  try {
    const response = await axios.post(
      `${api}/templates/${templateId}/section/signer`
    );

    dispatch({
      type: types.ADD_SIGNER_SECTION_TO_TEMPLATE,
      payload: response.data,
    });
    callBack();
  } catch (error) {
    console.log('error on add section: ', error);
    dispatch({
      type: types.CONTRACT_AUTOSAVE_FAILED,
    });
  }
};

export const addSectionToTemplate = (
  templateId,
  callBack
) => async dispatch => {
  try {
    const response = await axios.post(
      `${api}/templates/${templateId}/section/`
    );

    dispatch({
      type: types.ADD_SECTION_TO_TEMPLATE,
      payload: response.data,
    });
    callBack();
  } catch (error) {
    console.log('error on add section: ', error);
    dispatch({
      type: types.CONTRACT_AUTOSAVE_FAILED,
    });
  }
};

export const addPackageToSection = (sectionId, callBack) => async dispatch => {
  try {
    const response = await axios.post(`${api}/sections/${sectionId}/packages/`);
    console.log('response: ', response);
    dispatch({
      type: types.ADD_PACKAGE_TO_SECTION,
      payload: response.data,
    });
    callBack();
  } catch (error) {
    console.log('error: ', error);

    dispatch({
      type: types.CONTRACT_AUTOSAVE_FAILED,
    });
  }
};

export const addItemToSection = (
  sectionId,
  itemObject,
  callBack
) => async dispatch => {
  try {
    const response = await axios.post(`${api}/sections/${sectionId}/item/`, {
      itemObject,
    });

    dispatch({
      type: types.ADD_ITEM_TO_SECTION,
      payload: response.data,
    });
    callBack();
  } catch (error) {
    console.log('error: ', error);

    dispatch({
      type: types.CONTRACT_AUTOSAVE_FAILED,
    });
  }
};

export const addItemToPackage = (
  packageId,
  itemType,
  callBack
) => async dispatch => {
  try {
    const postBody = {
      itemName: `New ${itemType} item`,
      itemSettings: {
        billCycle: 'monthly',
        price: 0,
        discountBillCycle: 'oneTime',
      },
      itemType,
    };

    const response = await axios.post(
      `${api}/packages/${packageId}/item/`,
      postBody
    );

    dispatch({
      type: types.ADD_ITEM_TO_PACKAGE,
      payload: response.data,
    });
    callBack();
  } catch (error) {
    console.log('error: ', error);

    dispatch({
      type: types.CONTRACT_AUTOSAVE_FAILED,
    });
  }
};

export const deleteItemIcon = (
  itemId,
  itemUploadId,
  callBack = () => { }
) => async dispatch => {
  try {
    dispatch({
      type: types.LOADING_ITEM_ICON,
      payload: { itemId },
    });

    const res = await axios.delete(`${api}/itemuploads/${itemUploadId}`);

    dispatch({
      type: types.DELETE_ITEM_ICON,
      payload: { itemId, itemUploadId, data: res.data },
    });

    callBack();
    return true;
  } catch (error) {
    console.log('error: ', error);

    dispatch({
      type: types.CONTRACT_AUTOSAVE_FAILED,
    });
    return false;
  }
};

export const uploadItemIcon = (itemId, file) => async dispatch => {
  if (file && file.status === 'done') {
    dispatch({
      type: types.UPLOAD_ITEM_ICON,
      payload: { itemId, itemUpload: file.response.itemUpload },
    });
  }
};

export const sendUserFeedback = (
  rating,
  text,
  handsOn,
  contractId,
  signerToken,
  cb
) => async dispatch => {
  try {
    const response = await axios.post(`${api}/feedback/`, {
      rating,
      text,
      handsOn,
      contractId,
      signerToken,
    });

    if (cb) cb();

    dispatch({
      type: types.USER_FEEDBACK_SENT,
      payload: response.data,
    });
  } catch (error) {
    console.error(error);
  }
};
