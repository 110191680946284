/**
 * All _non-secret_ configuration for each individual environment.
 *
 * Approach found and used from:
 *   https://serverless-stack.com/chapters/environments-in-create-react-app.html
 */

///////////
const local = {
  app: {
    URL: process.env.REACT_APP_APP_URL || 'http://localhost:20521',
  },
  api: {
    URL: process.env.REACT_APP_API_URL || 'http://localhost:47764/rest/v1',
  },
};

/////////
const qa = {
  app: {
    URL: 'https://qa.contractsv2.madwire.network',
  },
  api: {
    URL: 'https://qa.contractsv2.madwire.network/rest/v1',
  },
};

/////////
const stage = {
  app: {
    URL: 'https://contractsv2.madwire.network',
  },
  api: {
    URL: 'https://contractsv2.madwire.network/rest/v1',
  },
};

//////////
const prod = {
  app: {
    URL: 'https://contractsv2.marketing360.com',
  },
  api: {
    URL: 'https://contractsv2.marketing360.com/rest/v1',
  },
};

/// -- // - // -- ///
let config;

switch (process.env.REACT_APP_ENV_MODE) {
  case 'local':
    config = local;
    break;
  case 'qa':
    config = qa;
    break;
  case 'stage':
    config = stage;
    break;
  default:
    config = prod;
}

export default {
  // Add common config values here
  ENV: process.env.REACT_APP_ENV_MODE,
  ...config,
  sentryDSN: 'https://4648d370e3bd46e88f2f15e64cbf8b47@sentry.io/1776987',
};
